body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
@font-face {
    font-family: "HelveticaNeueNormal";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/HelveticaNeueLTStd-Cn.otf");
}

@font-face {
    font-family: "HelveticaNeueBold";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/HelveticaNeueLTStd\ MdCn.otf");
}
@font-face {
    font-family: "HelveticaNeueThin";
    font-weight: normal;
    font-style: normal;
    src: url("./assets/fonts/HelveticaNeueLTStd\ ThCn.otf");
}
